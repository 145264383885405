<template>
  <div>
    <a-card title="登录日志">
      <a-row gutter="16">
        <a-col :span="24" style="width: 256px">
          <a-range-picker @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.creator" placeholder="登录人员" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in userItems" :key="item.id" :value="item.id">{{ item.name }} </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.status" placeholder="登录状态" allowClear style="width: 100%" @change="search">
            <a-select-option value="success">成功</a-select-option>
            <a-select-option value="failure">失败</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { userOption } from "@/api/option";
import { loginLogList } from "@/api/system";

export default {
  components: {},
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "操作人员",
          dataIndex: "creator",
          customRender: (_, item) => item.creator_item.name,
        },
        {
          title: "主机",
          dataIndex: "remote_ip",
        },
        {
          title: "登录状态",
          dataIndex: "status_display",
        },
        {
          title: "访问日期",
          dataIndex: "create_time",
        },
      ],
      searchForm: { search: "", page: 1, page_size: 10 },
      pagination: { current: 1, total: 0, pageSize: 10 },
      loading: false,
      items: [],
      userItems: [],
    };
  },
  computed: {},
  methods: {
    initData() {
      userOption({ page_size: 99999 }).then((resp) => {
        this.userItems = resp.results;
      });

      this.list();
    },
    list() {
      this.loading = true;
      loginLogList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      if (sorter.field) {
        this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      }
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0];
      let endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
